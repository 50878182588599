import React from 'react';
import ContentRecommendationSection from 'components/MembershipPDP/ContentRecommendationSection';

import { PageSectionType } from 'utils/types';

export type ContentRecommendationContainerProps = {
  content: PageSectionType;
  isMember: boolean;
  loggedIn: boolean;
};

export const ContentRecommendationContainer: React.FC<ContentRecommendationContainerProps> =
  ({ content, isMember, loggedIn }: ContentRecommendationContainerProps) => {
    if (!content?.title || !content?.description) return null;

    return (
      <ContentRecommendationSection
        content={content}
        isMember={Boolean(isMember)}
        loggedIn={Boolean(loggedIn)}
      />
    );
  };

export default ContentRecommendationContainer;
