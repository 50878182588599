import {
  Button as RawButton,
  mediaQueries,
  size,
  typography,
  colors,
  H4,
} from '@everlywell/leaves';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

export const ContentRecommendationContainer = styled.div`
  display: flex;
  background: #eef5f6;
  overflow: hidden;
  margin-top: ${size.xl4}px;

  ${mediaQueries.forTabletVerticalDown} {
    flex-direction: column;
  }
`;

export const ImageContainer = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  ${mediaQueries.forTabletVerticalDown} {
    width: 100%;
  }

  ${mediaQueries.forPhoneOnly} {
    max-height: 192px;
  }
`;

export const ImageWapper = styled(GatsbyImage)`
  display: block;
  width: auto;
  height: 100%;
  align-self: center;

  ${mediaQueries.forPhoneOnly} {
    max-height: 192px;
  }
`;

export const ContentWrapper = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  padding: ${size.lg}px;

  ${mediaQueries.forTabletVerticalDown} {
    width: 100%;
  }
`;

export const Content = styled.div``;

export const Title = styled(H4)`
  padding: 0 0 ${size.md}px;
  ${mediaQueries.forTabletHorizontalUp} {
    padding-bottom: ${size.lg}px;
  }
`;

export const Button = styled(RawButton)`
  color: ${colors.green5};
  font-size: 18px;
  line-height: ${size.lg}px;
`;

export const BodyContent = styled.p`
  ${typography.bodyTextSmall}
  padding-bottom: ${size.md}px;
  ${mediaQueries.forTabletHorizontalUp} {
    padding-bottom: ${size.lg}px;
  }
`;
