import { breakpoints, Container } from '@everlywell/leaves';
import SectionWrapper from 'components/SectionWrapper';
import { TrackVisibilityWrapper } from 'components/TrackVisibilityWrapper';
import React from 'react';
import analytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { PageSectionType } from 'utils/types';

import * as S from './styles';

export interface Props {
  content: PageSectionType;
  isMember: boolean;
  loggedIn: boolean;
}

const ContentRecommendationSection = ({
  content,
  isMember,
  loggedIn,
}: Props) => {
  const {
    description,
    desktopImages,
    mainCta,
    mobileImages,
    slug = '',
    title = '',
  } = content;

  const onClick = async () => {
    const targetURL = mainCta?.url;

    await analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.CONTENT_RECOMMENDATION_SECTION.label,
        targetURL,
        isMember: Boolean(isMember),
        loggedIn: isMember ? true : loggedIn,
      },
    });

    if (targetURL) window.location.href = targetURL;
  };

  const isMobile = window.innerWidth <= breakpoints.forPhoneOnly;

  return (
    <TrackVisibilityWrapper
      label={ANALYTICS.LABELS.CONTENT_RECOMMENDATION_SECTION.label}
      slug={content.slug}
    >
      <SectionWrapper data-testid={slug} id={slug}>
        <Container>
          <S.ContentRecommendationContainer>
            {isMobile && mobileImages && (
              <S.ImageContainer>
                <S.ImageWapper
                  image={mobileImages[0].gatsbyImageData}
                  alt={mobileImages[0].description}
                />
              </S.ImageContainer>
            )}
            {!isMobile && desktopImages && (
              <S.ImageContainer>
                <S.ImageWapper
                  image={desktopImages[0].gatsbyImageData}
                  alt={desktopImages[0].description}
                />
              </S.ImageContainer>
            )}
            <S.ContentWrapper>
              <S.Content>
                <S.Title>{title}</S.Title>
                <S.BodyContent>{description?.description}</S.BodyContent>
                {mainCta?.url && (
                  <S.Button
                    appearance={mainCta?.appearance || 'primary'}
                    hasArrow
                    onClick={onClick}
                  >
                    {mainCta?.text}
                  </S.Button>
                )}
              </S.Content>
            </S.ContentWrapper>
          </S.ContentRecommendationContainer>
        </Container>
      </SectionWrapper>
    </TrackVisibilityWrapper>
  );
};

export default ContentRecommendationSection;
